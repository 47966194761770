"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Seo = function Seo() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables
   * @private
   */
  var $isExperience = document.querySelector('html[data-experience]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Add .big class for animation on mobile and remove it on desktop
    if ($isExperience) {
      // Get seo data and parse string to json
      var seoData = JSON.parse($isExperience.dataset.experience);

      // Update meta tags
      document.title = seoData.seoTitle;
      document.querySelector('meta[name="description"]').content = seoData.seoDescription;
      document.querySelector('meta[name="og:image"]').content = seoData.seoImage;
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();